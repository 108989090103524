.nav {
    .list {
        display: flex;
        flex-flow: row nowrap;
    }

    .link {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: inherit;
        text-decoration: none;
        white-space: nowrap;
        padding-bottom: 16px;

        &::after {
            position: absolute;
            bottom: 10px;
            content: "";
            width: 0;
            display: inline-block;
            height: 2px;
            background-color: var(--blue);
            transition: width .1s ease-in-out;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }

        .dmax {
            display: none;
            height: 16px;

            @media @desktop {
                display: block;
            }
        }
    }

    .item {
        position: relative;
        padding: 0 24px;
        line-height: 1.125;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            display: block;
            width: 1px;
            height: 18px;
            background-color: var(--border-grey);
        }

        &.dpro .link::after {
            background-color: var(--purple);
        }

        &:first-child::before {
            content: unset;
        }

        &:nth-child(2) {
            color: var(--red);
            font-family: var(--font-bold);

            .link::after {
                background-color: var(--red);
            }

            &.dpro {
                color: var(--dark-grey);

                .link::after {
                    background-color: var(--dark-grey);
                }
            }
        }

        &:last-child {
            border-right: 0;
        }

        .toggle-item {
            display: inline-flex;
            margin: 0;
            padding-left: 40px;
            border: 0;
            font-size: 16px;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuODk1IDBoMjAuMjFhMS44OTUgMS44OTUgMCAxIDEgMCAzLjc5SDEuODk1YTEuODk1IDEuODk1IDAgMSAxIDAtMy43OVptMCA3LjE3aDIwLjIxYTEuODk1IDEuODk1IDAgMSAxIDAgMy43OUgxLjg5NWExLjg5NSAxLjg5NSAwIDAgMSAwLTMuNzlabTAgNy4wNGgyMC4yMWExLjg5NSAxLjg5NSAwIDEgMSAwIDMuNzlIMS44OTVhMS44OTUgMS44OTUgMCAwIDEgMC0zLjc5WiIgZmlsbD0iIzM1MzUzNSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+), transparent;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }

    .level {
        &-3 {
            display: none;
            position: absolute;
            top: -1px;
            left: 213px;
            height: calc(~"100% + 2px");
            width: calc(~"1024px - 213px");
            padding: 16px;
            font-size: 13px;
            background-color: var(--white);
            z-index: 10;
            border: solid 1px var(--border-grey);
            pointer-events: none;
            cursor: default;

            @media @desktop {
                padding-left: 95px;
                width: calc(~"1280px - 213px");
            }

            @media @desktopXL {
                padding-left: 115px;
                width: calc(~"1600px - 213px");
            }

            &-column {
                flex: 1;
            }

            &-item {
                color: var(--dark-grey);
                margin-bottom: 8px;

                /* stylelint-disable-next-line */
                span {
                    display: inline-block;
                    font-family: var(--font-bold);
                    line-height: 1.3;
                    margin-bottom: 4px;
                    text-transform: uppercase;
                }
            }

            &-link {
                font-family: var(--font-bold);
                text-transform: uppercase;
            }
        }

        &-4 {
            margin-bottom: 24px;
        }
    }

    .level-3-link,
    .level-4-link {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        line-height: 1.3;
        margin-bottom: 4px;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    .menu {
        display: none;
        position: absolute;
        z-index: 10;
        top: 100%;
        left: -16px;
        width: 215px;
        background-color: var(--white);
        border: solid 1px var(--border-grey);
        pointer-events: none;

        .universe {
            border-bottom: solid 1px var(--border-grey);

            &:last-child {
                border: 0;
            }

            &-link {
                display: block;
                padding: 9px 17px;
                font-size: 13px;
                color: inherit;
                text-decoration: none;
            }
        }

        .js-open {
            background-color: var(--dark-grey);
            color: var(--white);

            .level-3 {
                display: flex;
                pointer-events: all;
            }
        }
    }

    .menu-btn {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        padding-left: 0;
        cursor: pointer;

        &.js-open {
            .menu {
                display: block;
                pointer-events: all;
            }
        }
    }
}
