@import "../variables/variables.less";
@import "logo.less";
@import "searchbar.less";
@import "user_menu.less";
@import "nav_bar.less";
@import "surheader.less";
@import "back_office.less";

.header {
    background: var(--white);
    margin: 0 -16px;
    padding: 0 16px;

    .row {
        height: 126px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 auto;
    }
}
