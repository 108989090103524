@font-face {
    font-family: Manrope;
    src: url(../../../version_common/styles/fonts/Manrope-Medium.eot);
    src:
        url(../../../version_common/styles/fonts/Manrope-Medium.eot?#iefix) format("embedded-opentype"),
        url(../../../version_common/styles/fonts/Manrope-Medium.woff2) format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Manrope Bold";
    src: url(../../../version_common/styles/fonts/Manrope-Bold.eot);
    src:
        url(../../../version_common/styles/fonts/Manrope-Bold.eot?#iefix) format("embedded-opentype"),
        url(../../../version_common/styles/fonts/Manrope-Bold.woff2) format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Party Bold";
    src: url(../../../version_common/styles/fonts/Party-Bold.eot);
    src:
        url(../../../version_common/styles/fonts/Party-Bold.eot?#iefix) format("embedded-opentype"),
        url(../../../version_common/styles/fonts/Party-Bold.woff2) format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@manrope: Manrope, Arial, sans-serif;
@manropeBold: "Manrope Bold", Arial, sans-serif;
@partyBold: "Party Bold", Arial, sans-serif;
