.header {
    .logo {
        margin-right: 16px;

        @media @desktop { margin-right: 51px; }

        .picto {
            display: block;
            height: 48px;
        }
    }
}
