:root {
    // Couleurs principales
    --red: #e21212;
    --red-hover: #d01111;
    --red-pressed: #bd0f0f;
    --blue: #006af5;
    --blue-hover: #0061e0;
    --blue-pressed: #0058cc;
    // Couleurs secondaires
    --marketplace: #008391;
    --marketplace-hover: #007682;
    --marketplace-pressed: #006974;
    --light-blue: #ccdff9;
    --light-blue-hover: #ccdff9;
    --light-blue-pressed: #b2cdf0;
    --purple: #662d91;
    --orange: #fe741b;
    --green: #007a38;
    --yellow: #ffdb00;
    // Etats
    --error: var(--red);
    --success: var(--green);
    --message: #fff199;
    --disabled: #d8d8d8;
    --text-disabled: #656565;
    // Noirs et blancs
    --black: #161616;
    --black-hover: #292929;
    --black-pressed: #333;
    --white: #fff;
    --white-hover: #e5e5e5;
    --white-pressed: #ccc;
    // 6 shades of grey
    --dark-grey: #333;
    --grey: #454545;
    --mid-grey: #757575;
    --soft-grey: #989898;
    --low-grey: #d8d8d8;
    --light-grey: #f5f5f5;
    --border-grey: #ececec; // revoir la couleur
    // Back office vendeur
    --border-color: var(--low-grey);
    --back-office: #c8ff00;
    --back-office-submit: #647f00;
    // Autres
    --overlay: rgba(0, 0, 0, 0.6);
    --overlay-light: rgba(0, 0, 0, 0.2);
    --shadow: 0 0 10px 0 rgba(69, 69, 69, 0.08);
    --shadow-bottom: 0 -5px 10px 0 rgba(69, 69, 69, 0.08);
    --shadow-sticky: 0 6px 10px 3px rgba(69, 69, 69, 0.16), 0 0 2px 0 rgba(69, 69, 69, 0.14);
    --shadow-conversational: 0 2px 4px 0 rgba(69, 69, 69, .08), 0 0 2px 0 rgba(69, 69, 69, .14);
}
