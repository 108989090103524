.block-thumbnails-slider {
    position: relative;
    z-index: 0;
    aspect-ratio: 1568/508;

    .thumbnails-slider {
        .splide__track {
            padding: 0;
        }
    
        .splide__arrows {
            width: 100%;
            padding: 0 8px;
            left: inherit;
            right: inherit;
        }
    
        .splide__pagination.punchlines-list-buttons {
            position: absolute;
            bottom: 16px;
            left: inherit;
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 0 16px;
        }
    
        .punchline-button {
            background-color: var(--white);
            min-width: 303px;
            height: 48px;
            border-radius: 4px;
            border: 1px solid var(--border-color);
            font-size: 16px;
            font-family: var(--font-regular);
            color: var(--black);
            cursor: pointer;
    
            &.is-active,
            &:hover {
                background-color: var(--black);
                border-color: var(--black);
                color: var(--white);
            }
        }
    }
    
    .thumbnail-picture {
        height: auto;
    }
}
