.surheader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -16px;
    padding: 8px 24px;
    background: var(--red);
    color: var(--white);
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
