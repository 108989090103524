.block-three-square {
    position: relative;
    margin: 16px 0;
    
    &-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        
        @media (max-width: 767px) {
            flex-direction: column;
            padding: 0 16px;
        }
    }

    &-element {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        max-width: 533px;
        overflow: hidden;
    }

    &-picture {
        width: 100%;
        height: auto;
    }
}
