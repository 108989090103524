@import "grs_autocomplete.less";

.searchbar {
    width: 100%;
    max-width: 875px;
    margin-right: 23px;
    position: relative;

    @media @desktop {
        margin-right: 45px;
    }

    .form {
        display: flex;
        flex-flow: row nowrap;
        height: 48px;
        border: solid 1px var(--dark-grey);
        border-radius: 3px;
    }

    .input {
        width: 100%;
        padding: 15px 16px;
        border: none;
        outline: none;
        background: var(--white);
        font-family: var(--font-regular);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::-webkit-search-cancel-button { cursor: pointer; }
    }

    .submit {
        flex: none;
        width: 48px;
        margin: 0;
        border: none;
        font-family: var(--font-bold);
        color: var(--white);
        background-color: var(--dark-grey);
        cursor: pointer;
    }

    .picto {
        display: inline-block;
    }

    .error-message {
        display: none;
        font-family: var(--font-bold);
        font-size: 12px;
        color: var(--red);
        margin-top: 8px;

        &.js-show {
            display: block;
        }
    }
}

.ui-helper-hidden-accessible {
    position: absolute;
    left: -9999px;
}
