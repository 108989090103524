.user-menu {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-flow: row nowrap;

    .item {
        position: relative;
        width: 161px;
        padding: 10px 0;
        flex: 1 1 0;

        @media @desktop { width: 182px; }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 41px;
            background-color: var(--border-grey);
        }
    }

    .label {
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
        margin-top: 8px;
        font-family: var(--font-bold);
        font-size: 12px;
        line-height: 1.2;
        position: relative;

        &.js-label-active {
            color: var(--dark-grey);
            text-decoration: underline;
        }

        &::after {
            position: absolute;
            bottom: -4px;
            content: "";
            width: 0;
            display: inline-block;
            height: 1px;
            background-color: var(--blue);
            transition: width .1s ease-in-out;
        }
    }

    .button,
    .link {
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        font-family: var(--font-regular);
        color: var(--dark-grey);
        line-height: 1;
        text-decoration: none;
        margin: 0 auto;
        padding: 0 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:active {
            outline: none;
        }

        &::before {
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: center;
        }

        &:hover {
            .label:not(.js-label-active)::after {
                width: 100%;
            }
        }

        &.help {
            &::before {
                height: 24px;
                width: 20px;
                background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTEwIDBjNS41MTcgMCAxMCA0LjQ4MyAxMCAxMHMtNC40ODMgMTAtMTAgMTBTMCAxNS41MTcgMCAxMCA0LjQ4MyAwIDEwIDBabTAgMTRhMSAxIDAgMSAwIDAgMiAxIDEgMCAwIDAgMC0yWm0wLTkuNDU1Yy0yLjAyOCAwLTMuNjgyIDEuNTQ0LTMuNjgyIDMuNDM3YS44NzguODc4IDAgMCAwIC40NzQuNzc1Yy4yOTcuMTYyLjY2NS4xNjIuOTYyIDBhLjg4Ljg4IDAgMCAwIC40NzMtLjc4MmMwLS45MTIuNzg5LTEuNjQ4IDEuNzczLTEuNjQ4Ljk4NCAwIDEuNzczLjczNiAxLjc3MyAxLjY1NSAwIC41MzItLjIwNC45MzQtLjY3MyAxLjM5NS0uMjY0LjI2LS41NC40NzMtMS4xNDUuOTA1bC0uMDk3LjA2OWMtLjIuMTQzLS4zMjYuMjMzLS40NTQuMzI5YS44Ny44NyAwIDAgMC0uMzU5LjY5NnYxLjI3M2EuODc4Ljg3OCAwIDAgMCAuNDc0Ljc3NWMuMjk3LjE2Mi42NjUuMTYyLjk2MiAwYS44OC44OCAwIDAgMCAuNDczLS43ODJjLjAwOC0uNTU5LjAxNC0uODQuMDE3LS44NDJsLjIyMy0uMTU4Yy4xODYtLjEzMy4zMjgtLjIzNi40NjktLjM0My4zMTEtLjIzNi41NzYtLjQ2LjgxNi0uNjk2Ljc2Ny0uNzU0IDEuMjAzLTEuNjE2IDEuMjAzLTIuNjIxIDAtMS44OTMtMS42NTQtMy40MzctMy42ODItMy40MzdaIiBmaWxsPSIjMzUzNTM1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) no-repeat center;
            }
        }

        &.geoloc {
            &::before {
                height: 24px;
                width: 19px;
                background-image: @picto_geoloc;
            }
        }

        &.login {
            .label {
                display: block;
                width: 100%;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &::before {
                height: 24px;
                width: 24px;
                background-image: @picto_user_login;
            }
        }

        &.js-dmax {
            &::before {
                height: 24px;
                width: 32px;
                background-image: @picto_dmax;
            }
        }

        &.basket {
            &::before {
                height: 24px;
                width: 24px;
                background-image: @picto_basket;
            }
        }
    }

    .indicator {
        display: none;
        position: absolute;
        top: 12px;
        right: calc(~"50% - 28px");
        transform: translateX(-50%);
        background: var(--red);
        height: 18px;
        width: 18px;
        border-radius: 100%;
        padding: 3px;
        font-size: 10px;
        line-height: 1;
        text-align: center;
        border: 1px solid var(--white);
        color: var(--white);

        &.show {
            display: block;
        }

        &.indicator-full {
            font-size: 9px;
        }
    }
}

.login {
    &-popin {
        display: none;
        width: 300px;
        position: absolute;
        top: 64px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 16px;
        background: var(--white);
        box-shadow: 0 0 2px 0 rgba(69, 69, 69, .16), 0 3px 6px 0 rgba(69, 69, 69, .16);
        border-radius: 3px;
        z-index: 10;

        &-item {
            border-bottom: solid 1px var(--light-grey);

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &-link {
        display: block;
        font-family: var(--font-bold);
        font-size: 14px;
        color: var(--dark-grey);
        text-decoration: none;
        padding: 16px 16px 16px 30px;
        transition: padding .3s ease-in-out;

        &:hover {
            padding-left: 36px;
        }

        &.icon {
            &-user-color {
                background-image: @picto_user_space;
                background-position: left 0 top 50%;
                background-repeat: no-repeat;
            }

            &-shop-color {
                background-image: @picto_user_store;
                background-position: left 0 top 50%;
                background-repeat: no-repeat;
            }

            &-order-color {
                background-image: @picto_user_box;
                background-position: left 0 top 50%;
                background-repeat: no-repeat;
            }

            &-logout {
                background-image: @picto_user_logout;
                background-position: left 0 top 50%;
                background-repeat: no-repeat;
                color: var(--black);
            }
        }
    }

    &-item:hover .login-popin:not(.js-hidden),
    &-item:focus .login-popin:not(.js-hidden),
    &-item.js-open .login-popin:not(.js-hidden) {
        display: block;
    }
}
