.back-office {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    flex-flow: row nowrap;
    padding: 8px 16px;
    margin: 0;
    background-color: var(--back-office);
    align-items: center;
    display: none;

    &.active {
        display: flex;
    }

    .conseiller {
        font-family: var(--font-bold);
        color: var(--red);
    }

    &-form {
        margin-left: auto;
    }

    .submit {
        color: var(--white);
        text-transform: uppercase;
        background-color: var(--back-office-submit);
        border: none;
        outline: none;
        padding: 4px 8px;
        border-radius: 4px;
        cursor: pointer;
    }
}
