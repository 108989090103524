
@font-face {
    font-family: "Open Sans";
    src: url(../../../version_common/styles/fonts/opensans-regular-webfont.eot);
    src:
        url(../../../version_common/styles/fonts/opensans-regular-webfont.eot?#iefix) format("embedded-opentype"),
        url(../../../version_common/styles/fonts/opensans-regular-webfont.woff2) format("woff2");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans Bold";
    src: url(../../../version_common/styles/fonts/opensans-bold-webfont.eot);
    src:
        url(../../../version_common/styles/fonts/opensans-bold-webfont.eot?#iefix) format("embedded-opentype"),
        url(../../../version_common/styles/fonts/opensans-bold-webfont.woff2) format("woff2");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}

@openSans: "Open Sans", Arial, sans-serif;
@openSansBold: "Open Sans Bold", Arial, sans-serif;

@fontRegular: @openSans;
@fontBold: @openSansBold;
@fontBoldAlt: @openSansBold;
