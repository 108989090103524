.grs-autocomplete {
    display: none;
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    transition: 250ms max-height ease-out;

    &.js-active {
        display: block;
    }

    .list {
        min-width: 500px;
        background-color: var(--white);
        box-shadow: 0 0 2px 0 rgba(69, 69, 69, .16), 0 3px 6px 0 rgba(69, 69, 69, .16);
        max-height: 1000px;
    }

    .item {
        font-size: 14px;
    }

    .link {
        display: block;
        color: var(--dark-grey);
        text-decoration: none;
        padding: 16px;
        border-radius: 3px;

        &::first-letter {
            text-transform: uppercase;
        }

        &:hover {
            background-color: var(--light-grey);
        }
    }
}
